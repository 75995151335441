import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {OverlayRef} from '@angular/cdk/overlay';
import {LANDING_LOCALE, MENU_OVERLAY_REF, SIGN_IN_OUTPUT} from '../landing-greetings/landing-greetings.component';
import { ExternalLinksType } from 'src/common/enum/external-links.enum';
import {CommonModule} from '@angular/common';
import {CommonPipesModule} from '../../../../common/pipes/common-pipes.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'fbr-landing-mobile-menu',
  templateUrl: './landing-mobile-menu.component.html',
  styleUrls: ['./landing-mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CommonPipesModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class LandingMobileMenuComponent {
  ExternalLinksType = ExternalLinksType;

  constructor(
    @Inject(MENU_OVERLAY_REF) private overlayRef: OverlayRef,
    @Inject(SIGN_IN_OUTPUT) private signInOutputFn: Function,
    @Inject(LANDING_LOCALE) public locale: string) { }

  closeMenu(): void {
    this.overlayRef.detach();
  }

  signIn(): void {
    this.signInOutputFn();
  }
}
