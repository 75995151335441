import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ExternalLinksType} from '../../../../common/enum/external-links.enum';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ILandingPartner} from '../../interfaces/landing-partner.interface';
import {TFbrLang} from '../../../../common/enum/lang.enum';
import {CommonModule} from '@angular/common';
import {CommonPipesModule} from '../../../../common/pipes/common-pipes.module';
import {TranslateModule} from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'fbr-landing-partners',
  templateUrl: './landing-partners.component.html',
  styleUrls: ['./landing-partners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CommonPipesModule,
    TranslateModule
  ],
})
export class LandingPartnersComponent {
  @Input() locale: string;

  readonly isLargeBreakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large])
    .pipe(
      map(() =>this.breakpointObserver.isMatched(Breakpoints.Large)),
      distinctUntilChanged()
    );

  partners: ILandingPartner[] = [{
    logoUrl: 'assets/images/partners/twoplustwo.svg',
    activeLogoUrl: 'assets/images/partners/twoplustwo-colored.svg',
    alt: '2+2 partner',
    mobileHeight: '42px',
    height: '53px',
    href: 'https://forumserver.twoplustwo.com/167/poker-software/freebetrange-build-train-apply-your-preflop-strategy-10-discount-1796790/'
  }, {
    logoUrl: 'assets/images/partners/hand2note.svg',
    activeLogoUrl: 'assets/images/partners/hand2note-colored.svg',
    alt: 'hand2note partner',
    mobileHeight: '38px',
    height: '68px',
    externalLinkType: ExternalLinksType.PARTNER_HAND2NOTE
  }, {
    logoUrl: 'assets/images/partners/getcoach.svg',
    activeLogoUrl: 'assets/images/partners/getcoach-colored.svg',
    alt: 'getcoach partner',
    mobileHeight: '38px',
    height: '70px',
    href: 'https://www.getcoach.poker/ '
  }, {
    logoUrl: 'assets/images/partners/pokerstrategy.svg',
    activeLogoUrl: 'assets/images/partners/pokerstrategy-colored.svg',
    alt: 'pokerstrategy partner',
    mobileHeight: '38px',
    height: '62px',
    externalLinkType: ExternalLinksType.PARTNER_POKERSTRATEGY
  },{
    logoUrl: 'assets/images/partners/smartev.svg',
    activeLogoUrl: 'assets/images/partners/smartev-colored.svg',
    alt: 'smartev partner',
    mobileHeight: '44px',
    height: '72px',
    externalLinkType: ExternalLinksType.PARTNER_SMARTEV,
    languages: [TFbrLang.en, TFbrLang.es]
  }, {
    logoUrl: 'assets/images/partners/worldpokerdeals.svg',
    activeLogoUrl: 'assets/images/partners/worldpokerdeals-colored.svg',
    alt: 'worldpokerdeals partner',
    href: 'https://worldpokerdeals.com/?ref=ViIO1vtgI3',
    mobileHeight: '28px',
    height: '50px'
  }, {
    logoUrl: 'assets/images/partners/pekarstas.svg',
    activeLogoUrl: 'assets/images/partners/pekarstas-colored.svg',
    alt: 'pekarstas partner',
    href: 'https://www.pekarstas.com/',
    mobileHeight: '36px',
    height: '72px'
  }, {
    logoUrl: 'assets/images/partners/sixplusholdem.svg',
    activeLogoUrl: 'assets/images/partners/sixplusholdem-colored.svg',
    alt: 'sixplusholdem partner',
    href: 'https://sixplusholdem.com/',
    mobileHeight: '38px',
    height: '70px',
    languages: [TFbrLang.en, TFbrLang.es]
  }, {
    logoUrl: 'assets/images/partners/pokerpopup.svg',
    activeLogoUrl: 'assets/images/partners/pokerpopup-colored.svg',
    alt: 'pokerpopup partner',
    externalLinkType: ExternalLinksType.PARTNER_POKERPOPUP,
    mobileHeight: '38px',
    height: '76px'
  }, {
    logoUrl: 'assets/images/partners/simplepoker.svg',
    activeLogoUrl: 'assets/images/partners/simplepoker-colored.svg',
    alt: 'simplepoker partner',
    externalLinkType: ExternalLinksType.PARTNER_SIMPLEPOKER,
    mobileHeight: '38px',
    height: '76px'
  }];

  ExternalLinksType = ExternalLinksType;

  constructor(private breakpointObserver: BreakpointObserver) {
  }

}
